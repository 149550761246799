<template>
  <div class="iss-main-grid fanContent">
    <a-spin :spinning="spinning" size="small">
      <div class="iss-search-wrap">
        <div class="fanTitle">
          <div class="fanM">粉丝管理</div>
          <a
            href="jvoid(0)"
            class="fontStyle follow"
            style="color: #333; text-decoration: underline"
          >
            关联公众号</a
          >
        </div>

        <search-form
          :items="items"
          @fnSearch="handleFnSearch"
          v-if="activeKey === '1'"
        />
        <search-form :items="itemsT" @fnSearch="handleFnSearch" v-else />
      </div>

      <div class="iss-grid-wrap mt-20">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="已关注">
            <iss-followed
              ref="followedRef"
              :isSeach="search"
              :lastUpdateTime="lastUpdateTime"
              :loadingT="loading"
              @fnGrid="handleGrid"
              @updateFanStatus="updateFanStatus"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="黑名单">
            <issBlacked
              ref="blackedRef"
              :isSeach="searchT"
              :lastUpdateTime="lastUpdateTime"
              :loadingT="loading"
              @updateFanStatus="updateFanStatus"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Tabs, Spin } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import { onMounted, reactive, toRefs, watch, ref } from 'vue';
import issFollowed from '@/views/wechat/fanManagement/components/followed';
import issBlacked from '@/views/wechat/fanManagement/components/blocked';
import { useStore } from 'vuex';
import moment from 'moment';
import { message } from 'ant-design-vue';
import wechatApi from '@/api/wechatServer';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    SearchForm,
    issFollowed,
    issBlacked,
    ASpin: Spin,
  },

  setup() {
    const store = useStore();
    const followedRef = ref(null);
    const blackedRef = ref(null);
    const state = reactive({
      activeKey: '1',
      search: {},
      searchT: {},
      lastUpdateTime: '',
      loading: false,
      appId: store.state.account.appId,
      // wxTagList: [],
      list: [],
      spinning: false,
    });
    watch(
      () => state.activeKey,
      value => {
        console.log('state.activeKey', value);
        if (value === '1') {
          state.search = {};
          followedRef?.value?.updateList();
        } else {
          state.searchT = {};
          blackedRef?.value?.updateList();
        }
      }
    );
    const getUpdateTimeFun = () => {
      wechatApi.getUpdateTime({}, store.state.account.appId).then(res => {
        if (res) {
          if (!res?.lastUpdatePojo.createTime) {
            state.lastUpdateTime = moment().format('YYYY-MM-DD 00:00:00');
          } else {
            state.lastUpdateTime = res.lastUpdatePojo.createTime;
          }
        }
      });

      wechatApi.getWxTagList('', state.appId).then(res => {
        if (res) {
          let array = [];
          res.map(item => {
            array.push({
              value: item.tagId,
              label: item.name,
              key: item.tagId,
            });
          });
          console.log('下拉标签2', array);
          Object.assign(state.list, array);
        }
      });
    };
    onMounted(() => {
      getUpdateTimeFun();
    });

    const wxList = () => {
      location.reload();
      wechatApi.getWxTagList('', state.appId).then(res => {
        if (res) {
          let array = [];
          res.map(item => {
            array.push({
              value: item.tagId,
              label: item.name,
              key: item.tagId,
            });
          });
          console.log('下拉标签', array);
          Object.assign(state.list, array);
        }
      });
    };

    // 筛选的值需要区分是否当前是处于哪个tab， blackContact  是否是黑名单用户 false:否 true:是

    return {
      ...toRefs(state),
      followedRef,
      blackedRef,
      wxList,
      handleFnSearch: value => {
        console.log('handleFnSearch value', value);
        if (state.activeKey === '1') {
          state.search = value;
          state.searchT = {};
          console.log('state.search', value);
        } else {
          state.searchT = value;
          state.search = {};
          console.log('state.searchT', value);
        }
      },
      items: [
        { key: 'openId', label: 'openId' },
        {
          key: 'tagIdQuery',
          label: '标签',
          type: 'select',
          // showSearch: true,
          dataset: state.list,
        },
        {
          key: 'subscribe',
          label: '关注状态',
          type: 'select',
          dataset: [
            { value: 0, label: '未关注' },
            { value: 1, label: '已关注' },
          ],
        },
        // endCreateTime
        {
          key: 'creatTime',
          label: '关注时间',
          type: 'rangepicker',
          parmasName: ['startCreateTime', 'endCreateTime'],
        },
      ],
      itemsT: [
        { key: 'openId', label: 'openId' },
        {
          key: 'blackTime',
          type: 'rangepicker',
          parmasName: ['startCreateTime', 'endCreateTime'],
          label: '最后加入黑名单时间',
        },
      ],
      handleGrid(value) {
        // 获取 spinning
        console.log('handleGrid', value);
        state.spinning = value;
      },

      updateFanStatus() {
        state.loading = true;
        wechatApi
          .updateContact({}, { appId: store.state.account.appId })
          .then(() => {
            state.loading = false;
            getUpdateTimeFun();
            message.success('同步成功');
            // 更新筛选下拉
            setTimeout(() => {
              wxList();
            }, 200);

            followedRef?.value?.updateList();
            blackedRef?.value?.updateList();
          });
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.fanContent {
//  height: calc(100vh - 68px);
//  overflow-y: auto;
//}

.fanContent /deep/ .ant-tabs-bar {
  margin: 0px;
}

.fanTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .fanM {
    color: #333333;
    font-weight: 550;
    font-size: 16px;
  }
  .follow {
  }
}
</style>
